import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О нас | Откройте для себя тренажерный зал FlexFit
			</title>
			<meta name={"description"} content={"Скульптурируйте свое тело, преображайте свою жизнь - FlexFit Gym, ваш путь к совершенству в фитнесе"} />
			<meta property={"og:title"} content={"О нас | Откройте для себя тренажерный зал FlexFit"} />
			<meta property={"og:description"} content={"Скульптурируйте свое тело, преображайте свою жизнь - FlexFit Gym, ваш путь к совершенству в фитнесе"} />
			<meta property={"og:image"} content={"https://flixuso.com/img//1.jpg"} />
			<link rel={"shortcut icon"} href={"https://flixuso.com/img/gym-fitness.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://flixuso.com/img/gym-fitness.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://flixuso.com/img/gym-fitness.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://flixuso.com/img/gym-fitness.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://flixuso.com/img/gym-fitness.png"} />
			<meta name={"msapplication-TileImage"} content={"https://flixuso.com/img/gym-fitness.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://flixuso.com/img//4.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
					О нас
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					В тренажерном зале FlexFit мы верим, что фитнес - это не просто физические упражнения, это путешествие к самопознанию и расширению возможностей. Располагаясь в гостеприимном и поддерживающем сообществе, мы предоставляем не просто место для тренировок, мы предлагаем пространство, где вы можете бросить себе вызов, превзойти свои границы и стать лучшей версией себя.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box width="100%" margin="0px 0px 64px 0px" md-margin="0px 0px 30px 0px">
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					width="45%"
					lg-width="100%"
					lg-text-align="center"
				>
					Сердцебиение тренажерного зала FlexFit
				</Text>
			</Box>
			<Box display="flex" width="100%" md-flex-wrap="wrap">
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					margin="0px 3% 0px 0px"
				>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="40% 0px 0px 0px"
						border-radius="24px"
						md-margin="0px 0px 20px 0px"
					>
						<Image
							src="https://flixuso.com/img//5.jpg"
							width="100%"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							right={0}
							max-height="100%"
						/>
					</Box>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						В Shine Masters мы считаем, что автомобиль - это не просто средство передвижения, это отражение путешествия, продолжение личного стиля и свидетельство заботы о владельце. Наши услуги призваны улучшить и сохранить сущность вашего автомобиля, чтобы он производил впечатление, куда бы ни вела дорога.
					</Text>
				</Box>
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0 0px 0 0px"
				>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="40% 0px 0px 0px"
						border-radius="24px"
						margin="0px 0px 40px 0px"
						md-margin="0px 0px 20px 0px"
					>
						<Image
							src="https://flixuso.com/img//6.jpg"
							width="100%"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							right={0}
							max-height="100%"
						/>
					</Box>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Тренажерный зал FlexFit, основанный на нашей приверженности делу укрепления здоровья и хорошего самочувствия, - это пульс фитнес-совершенствования. Мы стремимся создать атмосферу, которая вдохновляет и мотивирует наших клиентов на достижение их фитнес-целей, по одному повторению за раз, начиная с самого современного оборудования и заканчивая советами экспертов.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://flixuso.com/img//7.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://flixuso.com/img//8.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead"
					lg-text-align="center"
					md-text-align="left"
				>
					Почему стоит выбрать тренажерный зал FlexFit?
				</Text>
				<Text
					margin="5px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Современное оборудование: Наш тренажерный зал оснащен самыми современными тренажерами и удобствами, обеспечивая вас всем необходимым для повышения эффективности тренировок.
					<br /><br />
					Сертифицированные тренеры: Наша команда сертифицированных тренеров стремится помочь вам достичь ваших фитнес-целей с помощью индивидуальных планов тренировок и квалифицированного руководства.
					<br /><br />
					Поддержка сообщества: Присоединяйтесь к активному сообществу единомышленников, которые разделяют вашу страсть к фитнесу и поддерживают друг друга в достижении своих целей.
					<br /><br />
					Разнообразные предложения по занятиям: От силовых тренировок до йоги и всего, что между ними, - мы предлагаем разнообразные занятия, чтобы удовлетворить различные интересы и уровни физической подготовки.

				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});